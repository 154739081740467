import {request} from './request'
import baseUrlObj from "./baseURL"
const systemNumber = baseUrlObj.systemNumber

// 获取认证证书信息 /ewi/guest/Cert/QueryCert2List
export function queryCert2List(formData){
  return request({
    url: '/ewi/guest/Cert/QueryCert2List',
    method: 'post',
    data: {
      authCode: formData.authCode,
      certNumber: formData.certNumber,
      uuid: formData.uuid,
      systemNumber: systemNumber,
    }
  })
}
